$background-gradient-start: #c7bfe6;
$background-gradient-end: #ecb0e3;
$background-fallback: #e899be;
$accent: #ca3166;

$danger: #c62828;
$success: #2e7d32;
$info: #283593;

$deviantart: #05cc46;
$facebook: #1778f2;
$instagram: #e4405f;
$tumblr: #35465d;
$twitter: #55acee;
$youtube: #e52d27;
$patreon: #f96854;
