@import './globals';

@mixin tablet-break {
  @media only screen and ( max-width: $desktop-width ) {
    @content
  }
};

@mixin mobile-break {
  @media only screen and ( max-width: $tablet-width ) {
    @content
  }
};
