@import 'colors';
@import 'mixins';

$icon-size: 50px;
$mobile-icon-size: $icon-size - 8px;

.icon {
  display: inline-block;
  margin: 5px;
  width: $icon-size;
  height: $icon-size;
  text-align: center;
  line-height: $icon-size;
  font-size: 1.3em;
  border: 2px solid;
  border-radius: 100%;

  transition: color 120ms linear, background 120ms linear;

  @include mobile-break {
    width: $mobile-icon-size;
    height: $mobile-icon-size;
    line-height: $mobile-icon-size - 4px;

    font-size: 1.1em;
  }

  &.deviantart {
    border-color: $deviantart;
    color: $deviantart;

    &:hover {
      background: $deviantart;
    }
  }

  &.facebook {
    border-color: $facebook;
    color: $facebook;

    &:hover {
      background: $facebook;
    }
  }

  &.instagram {
    border-color: $instagram;
    color: $instagram;

    &:hover {
      background: $instagram;
    }
  }

  &.tumblr {
    border-color: $tumblr;
    color: $tumblr;

    &:hover {
      background: $tumblr;
    }
  }

  &.twitter {
    border-color: $twitter;
    color: $twitter;

    &:hover {
      background: $twitter;
    }
  }

  &.youtube {
    border-color: $youtube;
    color: $youtube;

    &:hover {
      background: $youtube;
    }
  }

  &.patreon {
    border-color: $patreon;
    color: $patreon;

    &:hover {
      background: $patreon;
    }
  }

  &:hover {
    color: #fff;
  }
}
